// Variable overrides

$primary:                             #C24069;
$info: #C24069;
$dark:                                #000000;

$gray-300:                            #cfcfcf; // lighten($gray-base, 70%);
$gray-400:                            #bcacac; // lighten($gray-base, 60%);
$gray-500:                            #a68f8f; // lighten($gray-base, 50%);
$gray-600:                            #7c7575; // lighten($gray-base, 40%);
$gray-700:                            #735c5c; // lighten($gray-base, 30%);
$gray-800:                            #3a2f2f;
$gray-900:                            #2e2626; //DARK components

// Costum Colors

$body-bg:                             #e4e5e6;
$gray-body:                           #e4e5e6;
$table-dark-bg:                       #444444;

// Custom Styles

$navbar-height:                       70px;
$navbar-brand-width:                  210px;
$navbar-brand-minimized-width:        40px;


$sidebar-width:                       200px;
$sidebar-minimized-width:             60px;
$sidebar-bg:                         #313131;
$sidebar-nav-title-padding-y:         1rem;
$sidebar-nav-title-padding-x:         1.5rem;
$sidebar-nav-link-padding-y:          1rem;
$sidebar-nav-link-padding-x:          1.5rem;
$sidebar-nav-link-hover-bg:           #C24069;
$sidebar-nav-link-hover-icon-color:   rgb(255, 255, 255);
$sidebar-nav-link-active-bg:          #C24069;
$sidebar-nav-link-active-icon-color:  rgb(255, 255, 255);
