// Fabtec Template
.bodybackg {
    background-color: #ffffff;
}

.body {
    position: center;
}

.bg-light-grey {
    color: #333;
    background: #e4e5e6;
}

.bg-grey {
    color: #333;
    background: #c3c3c3;
}

.bg-trans {
    background: transparent !important;
}

.bg-ldark {
    background: #444444;
    color: #ffffff;
}

.bg-p {
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
}

// Brand & sidebar
.app-header .navbar-brand {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar {
    background: #FFFFFF;
    background: #313131;
}

.sidebar .nav-link:hover {
    color: #FFFFFF;
    background: #C24069;
}

.img-avat {
    border-radius: 50em;
    border: 3px rgba(255, 255, 255, 0.8) solid;
    width: 50%;
    transition: all ease-in-out $layout-transition-speed;
}

.img-avat:hover {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}

.navbar {
    padding: 0 !important;
}

.breadcrumb {
    background-color: #f1f1f1;
}

.app-footer {
    background-color: #f1f1f1;
}

.nav-dropdown-items .nav-item a {
    padding-left: 2.5rem;
    border-left: 5px rgba(255, 255, 255, 0.2) solid !important;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tooltip {
    transition: opacity ease-in-out $layout-transition-speed;
}

.text-p {
    color: #3e3935;
}

// Login Page
.login-bg-eff {
    background: rgb(235, 66, 66);
    background-size: 150% 150%;
    -webkit-animation: Gradient 1.5s ease infinite;
    -moz-animation: Gradient 1.5s ease infinite;
    animation: Gradient 1.5s ease infinite;
}

.fontlogo {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

.login-bg {
    // background: url('../../src/assets/img/dots.svg') no-repeat;
     background: rgba(255, 255, 255, 0.75);
    background-size: cover;
}

.login-logo-cont {
    background: #ffffff;
    width: 50%;
    min-height: 250px;
    height: 50%;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    border-bottom-right-radius: 250px;
    border-bottom-left-radius: 250px;
    display: block;
}

.custome-fadein-opt {
    animation: fadein 3s;
}

@keyframes fadein {
    from {
        opacity: 0;
        margin-top: -20px;
    }
    to {
        opacity: 1;
        margin-top: 0px;
    }
}

// Different Input Settings
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f0e6e6 inset !important;
    box-shadow: 0 0 0 30px #f0e6e6 inset !important;
}

.kep-login-facebook {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    border-style: solid;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: all ease\ -in-out $layout-transition-speed;
    background-color: #ffffff;
    border-color: #ba4c4c;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 0 30px 30px 30px;
}

.kep-login-facebook:hover {
    background-color: rgb(150, 61, 61);
    border-color: rgb(143, 60, 60);
}

// Different File & Radio input settings
.containerRB {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default radio button */

.containerRB input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.containerRB:hover input~.checkmark {
    background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.containerRB input:checked~.checkmark {
    background-color: #2196F3;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.containerRB input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.containerRB .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.file-preview {
    margin: 0 10px;
}

// Effects
.cursor-p {
    cursor: pointer;
}

.hover-shadow {
    transition: all ease-in-out $layout-transition-speed;
}

.hover-shadow:hover {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.45));
}

.hover-shadow-l {
    transition: all ease-in-out $layout-transition-speed;
}

.hover-shadow-l:hover {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.6));
}

.hover-opa {
    opacity: 0.7;
    transition: all ease-in-out $layout-transition-speed;
}

.hover-opa:hover {
    opacity: 1;
}

.hover-opa-m {
    opacity: 1;
    transition: all ease-in-out .8s;
}

.hover-opa-m:hover {
    opacity: 0.8;
}

.hover-opa-r {
    opacity: 1;
    transition: all ease-in-out $layout-transition-speed;
}

.hover-opa-r:hover {
    opacity: 0.7;
}

.hover-widen {
    transition: all ease-in-out $layout-transition-speed;
}

.hover-up:hover {
    padding-left: 2rem;
    padding-right: 2rem;
}

.hover-scale {
    transform: scale(1);
    transition: all ease-in-out $layout-transition-speed;
}

.hover-scale:hover {
    transform: scale(1.05);
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.blink-it {
    animation: blinker 1s linear infinite;
}

.blink-it:hover {
    animation: none;
}

.opacity-70 {
    opacity: 0.7;
}

.skew-x {
    -ms-transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
}

.w-90 {
    width: 90%;
}

.h-90 {
    height: 90%;
}

.h-88 {
    height: 88%;
}

.sm-box {
    width: 50px;
    transition: all ease-in-out $layout-transition-speed;
    background: // linear-gradient(
    //     135deg,
    //     rgba(255, 255, 255, 0.2),
    //     rgba(255, 255, 255, 0.2) 10px,
    //     rgba(255, 255, 255, 0.3) 10px,
    //     rgba(255, 255, 255, 0.3) 20px
    // )
    linear-gradient(335deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 49.92%, rgba(0, 0, 0, 0.1) 50%);
}

.border-l-2 {
    border-left: 1px #cccccc outset !important;
}

.table-pgn ul {
    margin-bottom: 0;
}

.border-w-2 {
    border-width: 2px !important;
}

.profile-pg .nav-link {
    border-color: #bbbbbb !important;
    background-color: #f1f1f1;
}

.card-header {
    background-color: #f1f1f1;
}

.card-footer {
    background-color: #f1f1f1;
}

.card-body-login {
background-color:  rgba(242, 236, 236, 0.3);
}

.border-0 .modal-content {
    border: none !important;
}

.mid-card-cont {
    width: 95%;
    margin: auto;
    border-radius: 15px;
    background-color: #e4e5e6;
    font-family: "poppins";
    font-size: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    display: flex;
    transition: all ease-in-out $layout-transition-speed;
    cursor: pointer;
}

.mid-card-cont:hover {
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
    opacity: 0.9;
}

.mid-card-title {
    flex: 2;
    padding: 8px;
    text-align: center;
}

.mid-card-value {
    flex: 1;
    background: #333;
    color: #ffffff;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mini-card {
    width: 100%;
    margin: auto;
    background-color: #ffffff;
    font-family: "poppins";
    font-size: 0.7rem;
    overflow: hidden;
    cursor: pointer;
}

.hover-red {
    color: $dark;
}

.hover-red:hover {
    color: $danger !important;
}

.radius-30 {
    border-radius: 30px;
}

.radius-l-30 {
    border-top-left-radius: 0px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 0px;
}

.radius-round-x {
    border-top-left-radius: 300px;
    border-bottom-left-radius: 300px;
}

.radius-xtl {
    border-top-left-radius: 0px !important;
}

.radius-rtl {
    border-top-right-radius: 0px !important;
}

.table-col-head {
    background-color: #dddddd;
    font-family: "poppins";
    font-size: 0.8rem;
    padding: 0.6rem 0.3rem !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.ver-a-mid td {
    vertical-align: middle;
}

.rbt-input-hint-container .form-control {
    border-radius: 15px !important;
    padding-left: 0.7rem;
}

.rbt-input-hint-container .form-control-sm {
    height: calc(2.0625rem + 2px);
}

.progress {
    background-color: rgba(0, 0, 0, 0.1);
}

.pagination {
    width: auto;
    margin: auto;
}

.pagin-right nav {
    width: auto;
    margin-left: auto;
}

.speedometer .current-value {
    display: none;
}

.dark-btn {
    background-color: #111111;
    border-color: #111111;
    color: #999999;
}

.filter-toggler:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.3);
}

.react-bootstrap-table tr {
    cursor: pointer;
}

.react-bootstrap-table .align-middle td,
.react-bootstrap-table .align-middle th {
    vertical-align: middle;
}

.table-img-cont {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    overflow: hidden;
    margin: auto;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
}

.column2 {
    float: left;
    width: 25%;
    padding: 0 10px 15px;
}


/* Remove extra left and right margins, due to padding */

.row2 {
    margin: 0 -5px;
}


/* Clear floats after the columns */

.row2:after {
    content: "";
    display: table;
    clear: both;
}


/* Responsive columns */

@media screen and (max-width: 600px) {
    .column2 {
        width: 100%;
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }
}


/* Style the counter cards */

.card2 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
}


/* Alerts styles override */

.alertify .ajs-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-style: italic !important;
}

.alertify-notifier .ajs-message.ajs-success {
    background: rgba(77, 189, 116, 0.8) !important;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3) !important;
    border-radius: 15px;
}

.alertify-notifier .ajs-message.ajs-error {
    color: #fff;
    background: rgba(160, 20, 20, 0.8) !important;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3) !important;
    border-radius: 15px;
}

.alertify-notifier .ajs-message.ajs-warning {
    background: rgba(255, 193, 7, 0.8) !important;
    border-radius: 15px;
    border-color: #999;
}

//checkbox
.multiselect {
    width: 200px;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    border: 1px #dadada solid;
}

#checkboxes label {
    display: block;
}

#checkboxes label:hover {
    background-color: #1e90ff;
}

.button-login {
position: relative;
background: #C24069;
color: rgb(255, 255, 255);
border-radius: 10px;
}

.DateRangePickerInput__withBorder,
.DateInput,
.DateInput_input {
    border-radius: 15px !important;
    text-align: center;
    font-size: 12px !important;
}

.DateRangePicker,
.DateRangePickerInput {
    width: 100%;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #ea5a5a !important;
    border: 1px double #ea5a5a !important;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #e68a8a !important;
    border: 1px double #ea5a5a !important;
    color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #e49b9b !important;
    border: 1px double #e68a8a !important;
    color: #efefef !important;
}

.DateInput__small {
    width: 45% !important;
    padding: 0 2% !important;
}